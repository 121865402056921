<template>
    <section id="secServiceAdvat">
        <v-container class="pb-15">
            <v-responsive>
                <v-container>
                    <v-layout wrap>
                        <v-flex 
                            d-flex flex-column md-12 xl-6 align-center 
                            class="mb-responsive"
                        >
                            <div class="w--grid--header--service">
                                <v-img
                                    width="70%"
                                    class="mx-auto"
                                    :src= "img"
                                    data-aos="flip-left"
                                    data-aos-duration="1500"
                                    data-aos-delay="100"
                                    data-aos-offset="300"
                                />
                            </div>
                            <div class="w--grid--header--service mt-3">
                                <div>
                                    <h1 
                                        class="mb-15 text-h6 text-md-h4 font-weight-black text-center white--text text-uppercase"
                                        data-aos="fade-up"
                                        data-aos-duration="1500"
                                        data-aos-delay="100"
                                        data-aos-offset="300"
                                    >
                                        {{ title }}
                                    </h1>
                                    <ul
                                        class="list-advantege"
                                        data-aos="fade-left"
                                        data-aos-duration="1500"
                                        data-aos-delay="100"
                                        data-aos-offset="300"
                                    >
                                        <li
                                            v-for="(list, i) of lists"
                                            :key="i"
                                            class="text-lg-h6 pt-3 px-5 text-body-1 white--text list-none font-weight-light"
                                        >
                                            <span>· </span> {{ list }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-responsive>
        </v-container>
    </section>
</template>

<script>
export default {
    name: 'SectionServicesAdvant',
    data() {
        return {
          img: require('@/assets/img/logo-services-ventajas.png'),
          title: 'Ventajas',
          lists: [
            'La duración del ciclo de fresado, demolición, retiro de escombros y cargue se reduce considerablemente pues la maquina lo efectúa en una sola operación.',
            'Se reducen los costos de operación y manejo de personal.',
            'Mediante la utilización de nuestras maquinas se obtiene un proceso ecológico y limpio que cuida al medio ambiente, ofreciendo una mayor velocidad de ejecución, con la ventaja adicional de permitir la utilización parcial de la vía en reparación y reducir al mínimo la contaminación auditiva.',
            'La posibilidad de estabilizar suelos in situ, reciclar o reutilizar el material resultante del fresado, implica una importante economía en el valor final de las obras, frente a los costos de consecución de materiales y transporte de los mismos en los sistemas tradicionales de construcción.',
            'Contamos con personal y operadores calificados con amplia experiencia en la operación y mantenimientos de los equipos de fresado.'
          ]
        }
    },
}
</script>

<style lang="scss">
#secServiceAdvat {
    background-color: #F35E22;
    .list-advantege { column-count: 2; }
    .list-none { 
        list-style: none;
        span { font-size: 2rem; }
    }

    @media (max-width: 800px) {
        .list-advantege { column-count: 1; }
    }
}
</style>